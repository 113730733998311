<template lang="pug">
.habit-confirmation-wrapper
  b-row(v-if="isSubmitting")
    b-col(cols="12")
      b-card.py-5
        b-card-body.text-center
          loading(
            :active="true" 
            :is-full-page="false"
            color="#498ceb"
          )

  div(v-else)
    validation-observer(ref="eventInfo", tag="form")
      b-row
        b-col(cols="12")
          b-card(:title="processName", :sub-title="process.goal")
            b-card-text
              | {{ process.description }}
        b-col(cols="12")
          b-card(:title="$t('message.information_event_confimation')")
            b-form-group
              label {{ $t('label.evaluator') }}
              validation-provider(:name="$t('label.evaluator')", rules="required", v-slot="{ errors }")
                v-select#v-select-worker(
                  v-model="selectedSupervisor",
                  :reduce="(supervisors) => supervisors.value",
                  :disabled="selectSupervisorDisabled",
                  :options="supervisors",
                  :placeholder="$t('placeholder.select_evaluador')",
                  label="title"
                )
                small(class="text-danger") {{ errors[0] }}
            b-form-group
              label {{ $t('label.evaluated') }}
              validation-provider(:name="$t('label.evaluated')", rules="required", v-slot="{ errors }")
                v-select#v-select-worker(
                  v-model="selectedWorker",
                  :reduce="(workersSupervised) => workersSupervised.value",
                  :disabled="selectWorkerDisabled",
                  :options="workersSupervised",
                  :placeholder="$t('placeholder.select_evaluado')",
                  label="title"
                )
                small(class="text-danger") {{ errors[0] }}
            b-form-group(:label="$t('label.fetch_confirmation')", label-for="v-date")
              b-form-datepicker#v-date(v-model="event.date", :disabled="selectDateDisabled")
            //b-form-group(label="Hora de inicio", label-for="v-time")
              b-form-timepicker#v-code(v-model="event.start")
            //b-form-group(label="Hora de término", label-for="v-time")
              b-form-timepicker#v-code(v-model="event.end")
            b-button(
              class="mt-1 mr-1"
              :disabled="isSubmitting"
              v-show="!continueConfirmation",
              v-ripple.400="'rgba(255, 255, 255, 0.15)'",
              variant="warning",
              @click="schedulleEvent()"
            )
              | {{ $t('message.program_confirmation_fetch') }}
            b-button(
              class="mt-1 mr-1"
              :disabled="isSubmitting"
              v-show="!continueConfirmation",
              v-ripple.400="'rgba(255, 255, 255, 0.15)'",
              variant="primary",
              @click="enableConfirmation"
            )
              | {{ $t('message.continuar_confirmation') }}

      // Metadata 
      b-row(v-show="process.metadata && process.metadata.length", :style="{ visibility: metadataVisible ? 'visible' : 'hidden' }")
        b-col(cols="12")
          b-card(:title="$t('aditional_information')")
            b-card-text
              b-form-group(
                v-for="(metadata, indexDos) in process.metadata", :key="indexDos"
                :label="$t(`metadata.${metadata.name}`)",
                :label-for="$t(`metadata.${metadata.name}`)",
                description=""   
              )
                validation-provider(
                  :name="$t(`metadata.${metadata.name}`)"
                  rules="required"
                  v-slot="{ errors }"
                )
                  v-select(
                    v-if="metadata.type === 'select'"
                    v-model="metadata.answer",
                    :multiple="false",
                    :options="metadataOptionsFiltered[metadata.name]",
                    :placeholder="$t(`placeholder.${metadata.name}`)",
                    @search="(search, loading) => onSearch(search, loading, metadata.name)",
                    v-b-tooltip.focus.v-info.top="$t('search_criteria')"
                    :key="'select'+indexDos"
                  )
                  b-form-input(
                    v-else-if="metadata.type === 'input'"
                    v-model="metadata.answer"
                    :placeholder="$t(`placeholder.${metadata.name}`)"
                  )
                  small(class="text-danger") {{ errors[0] }}

    div(v-show="continueConfirmation")

      // Controls
      b-row(v-for="(control, controlIdx) in process.controlsData", :key="controlIdx")
        b-col(cols="12")
          b-card(:title="control.name")
            b-row( v-for="(behaviour, behaviourIndex) in control.behavioursData", :key="behaviourIndex")
              b-col(cols="12")
                behaviour-question(
                  :behaviour="behaviour",
                  :allow-add-improvement="true",
                  :clientId="clientId",
                  :activityName="control.name",
                  @answered="(answer) => (behaviour.answer = answer)"
                  @improvementModified="(improvement) => (behaviour.improvement = improvement)"
                  @improvementRemoved="() => (delete behaviour.improvement)"
                  @fileToUpload="(file) => (behaviour.file = file)"
                )
                //- :allow-add-commitment="!commitment_functionality && isOnline",

      // Upload image
      b-row(v-if="process.requireImage")
        b-col(cols="12")
          b-card(
            :title="$t('message.upload_image')",
            :sub-title="$t('message.upload_image_description')"
          )
            b-card-text
              b-button.mr-1(
                :disabled="isSubmitting"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                type="button",
                variant="info",
                @click="uploadImage()"
              )
                | {{ $t('message.upload') }}
              image-compressor(
                style="display: none",
                :done="getImgCompressed",
                :scale="scale",
                :quality="quality",
                :maxWidth="maxWidth",
                ref="compressor"
              )
              small(v-if="imgData.fileInfo")
                | {{ imgData.fileInfo.name }}
              small(v-else, class="text-danger")
                | {{ $t('message.upload_image_required') }}

      // Add improvement
      b-row(v-if="add_improvement_in_confirmation")
        b-col(cols="12")
          b-card(
              :title="commitment_functionality ? $t('add_commitment') : $t('add_improvement')",
            )
            b-form-group
              b-col(cols="12")
                ul.list-group.mb-1
                  li.list-group-item.d-flex.justify-content-between.align-items-center.pt-0.pb-0(v-for="(improvement, index) in improvements" :key="index")
                    span
                      | {{ improvement.note }}
                    div
                      edit-button(@clicked="handleImprovementEdit(improvement, index)")
                      delete-button(v-b-modal="'improvement list' + index")
                      b-modal(
                        :id="'improvement list' + index" 
                        centered='' 
                        no-close-on-backdrop='' 
                        modal-class='modal-danger' 
                        ok-variant='danger' 
                        cancel-variant='outline-secondary' 
                        :title="$t('message.confirm_action')" 
                        :ok-title="$t('message.delete')" 
                        :cancel-title="$t('message.cancel')" 
                        @ok="removeImprovement(index)"
                      )
                        | {{ commitment_functionality ? $t('message.confirm_delete_commitment') : $t('message.confirm_delete_improvement') }}
                b-button(
                  :disabled="isSubmitting",
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'",
                  type="button",
                  variant="primary"
                  @click="isImprovementHandlerSidebarActive = true"
                )
                  | {{ $t('message.add') }}


      // End confirmation
      b-row
        b-col(cols="12")
          b-card(
            :title="$t('message.End_Confirmation')",
            :sub-title="$t('message.please_check_all_answers_correct')"
          )
            b-card-text
              b-button.mr-2(
                v-if="isOnline",
                :disabled="isSubmitting",
                v-ripple.400="'rgba(186, 191, 199, 0.15)'",
                type="button",
                variant="outline-primary"
                @click="validateAndSubmit(process, true)"
              )
                | {{ $t('message.save_draft') }}
              b-button(
                :disabled="isSubmitting || (process.requireImage && !imgData.fileInfo)",
                v-ripple.400="'rgba(255, 255, 255, 0.15)'",
                type="submit",
                variant="primary",
                @click="validateAndSubmit(process, false)"
              )
                | {{ $t('message.submit') }}

    todo-task-handler-sidebar(
      v-model="isImprovementHandlerSidebarActive",
      :task="improvement",
      :taskIndex="improvementIndex",
      :clear-task-data="clearImprovementData",
      :domains="domains",
      :optionsMetadata="optionsMetadata",
      handlerId="improvement",
      @add-task="addImprovement",
      @update-task="updateImprovement"
      @remove-task="removeImprovement"
    )
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch, set } from "@vue/composition-api";
import { BRow, BCol, BCard, BCardBody, BCardText, BButton, BFormGroup, BFormSelect, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useCommon from "@/views/organization/useCommon";
import useConfirmationForm from './useConfirmationForm'
import BehaviourQuestion from './BehaviourQuestion.vue'
import i18n from '@/libs/i18n'
import useNotifications from '@/composables/useNotifications'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import ImageCompressor from '@/views/habit/ImageCompressor.vue';
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { VBTooltip } from 'bootstrap-vue'
import TodoTaskHandlerSidebar from '@/views/apps/todo/TodoTaskHandlerSidebar.vue'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BFormGroup,
    BFormSelect,
    BehaviourQuestion,
    vSelect,
    BFormDatepicker,
    BFormTimepicker,
    ValidationObserver,
    ValidationProvider,
    ImageCompressor,
    TodoTaskHandlerSidebar,
    EditButton,
    DeleteButton,
    Loading,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isNewConfirmation: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    processName() {
      return i18n.t('message.process') + `: ${this.process.name || ''}`
    },
  },
  setup(props) {
    const {
      improvements, userData, show, submit, event, process, selectedWorker, selectWorkerDisabled, selectedSupervisor, selectSupervisorDisabled, schedulleEvent, continueConfirmation, isSubmitting, selectDateDisabled, enableConfirmation, interval, startTimer, stopTimer, metadataVisible,
    } = useConfirmationForm(props.isNewConfirmation)
    const { getWorkersSupervisedForDropDown, workersSupervised, getSupervisorsForDropDown, supervisors, getMetadataForDropDown, metadataTypeSelectNotMapped, metadataNotMapped } = useCommon()
    const isOnline = computed(() => store.state.app.isOnline)
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const clientId = userData.role !== "admin" ? userData.client.$oid : null;
    const eventInfo = ref(null)
    const compressor = ref(null)
    const imgData = ref({})
    const scale = ref(100)
    const quality = ref(50)
    const maxWidth = ref(640)
    const metadataOptionsFiltered = ref({})
    const improvementIndex = ref(-1)
    const isImprovementHandlerSidebarActive = ref(false)
    const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    const now = new Date()

    const { getDomains, getDatesFromRange } = useCommonDashboards()
    const domains = computed(() => getDomains(clientId))

    const blankImprovement = {
      client_id: { link: clientId },
      note: '',
      createdAt: now,
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      description: '',
      assignee: null,
      documentFile: {}
    }

    const improvement = ref(JSON.parse(JSON.stringify(blankImprovement)))

    const clearImprovementData = () => {
      improvement.value = JSON.parse(JSON.stringify(blankImprovement))
      improvementIndex.value = -1
    }

    const optionsMetadata = computed(() => store.state[TODO_APP_STORE_MODULE_NAME].optionsMetadata)

    watch(metadataNotMapped, val => {
      if (val && val.length) {
        val.forEach(e => {
          // Set default creation date
          if (e.name === "creation_date") {
            e.answer = `${now.getFullYear()}-${now.getMonth() < 9 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1}-${now.getDate() < 10 ? "0" + now.getDate() : now.getDate()}`
          }
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = userData.username
        })
        blankImprovement.metadata = val
        if (!improvement.value._id) improvement.value = { ...improvement.value, metadata: val }
      }
    })

    const handleImprovementEdit = (improvementData, index) => {
      improvementIndex.value = index
      improvement.value = improvementData
      isImprovementHandlerSidebarActive.value = true
    }

    const addImprovement = (improvementData) => {
      improvements.value.push(improvementData)
    }

    const updateImprovement = (improvementData, index) => {
      improvements.value[index] = improvementData
    }

    const removeImprovement = (index) => {
      improvements.value.splice(index, 1)
      isImprovementHandlerSidebarActive.value = false
    }

    const { router, route } = useRouter()

    const { commitment_functionality, add_improvement_in_confirmation } = JSON.parse(localStorage.getItem('clientData') || '{}')

    const uploadImage = () => {
      compressor.value.$el.click()
    }

    const getImgCompressed = (obj) => {
      imgData.value = {
        fileInfo: obj.compressed,
        destinationFolder: `${userData.client.$oid}/confirmations`
      }
    }

    const openTaskHandlerSidebar = () => {
      store.commit('app-todo/SET_TOGGLE_TASK_HANDLER_SIDEBAR', true)
    }

    const validateEventInfo = () => new Promise((resolve, reject) => {
      eventInfo.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = (process, isDraft) => {
      if (isDraft) submit(process, imgData.value, isDraft)
      else {
        validateEventInfo()
          .then(() => submit(process, imgData.value, isDraft))
          .catch((error) => {
            console.log(error)
            showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
          })
      }
    };

    const onSearch = (search, loading, property) => {
      // Only enable search when text input is at least 3 characters long
      if (search.length < 3) {
        metadataOptionsFiltered.value[property] = metadataTypeSelectNotMapped.value[property]?.slice(0, 100)
      } else {
        loading(true)
        // Simulated ajax query
        setTimeout(() => {
          metadataOptionsFiltered.value[property] = metadataTypeSelectNotMapped.value[property]?.filter(e => e.toLowerCase().includes(search.toLowerCase()))
          loading(false)
        }, 100)
      }
    }

    onMounted(async () => {
      await getWorkersSupervisedForDropDown()
      await getSupervisorsForDropDown()
      await getMetadataForDropDown({ type: "select", option: "notMapped" })
      await getMetadataForDropDown({ category: "improvement", option: "notMapped" })
      await show()
      if (route.value.params.user || props.isNewConfirmation) startTimer()
    })

    onUnmounted(() => {
      if (interval.value) stopTimer()
    })

    watch(metadataTypeSelectNotMapped, () => {
      for (const metadata in metadataTypeSelectNotMapped.value) {
        set(metadataOptionsFiltered.value, metadata, metadataTypeSelectNotMapped.value[metadata]?.slice(0, 100))
      }
    })

    return {
      show,
      submit,
      event,
      process,
      workersSupervised,
      supervisors,
      selectedWorker,
      selectWorkerDisabled,
      selectedSupervisor,
      selectSupervisorDisabled,
      selectDateDisabled,
      schedulleEvent,
      enableConfirmation,
      continueConfirmation,
      isSubmitting,
      required,
      eventInfo,
      validateAndSubmit,
      compressor,
      scale,
      quality,
      maxWidth,
      uploadImage,
      getImgCompressed,
      imgData,
      isOnline,
      commitment_functionality,
      add_improvement_in_confirmation,
      openTaskHandlerSidebar,
      onSearch,
      metadataOptionsFiltered,
      metadataVisible,
      isImprovementHandlerSidebarActive,
      improvement,
      improvements,
      domains,
      clearImprovementData,
      improvementIndex,
      handleImprovementEdit,
      addImprovement,
      removeImprovement,
      updateImprovement,
      optionsMetadata,
      clientId,
    }
  },
}
</script>

<style lang="scss">
.form-group {
  label {
    font-size: 1.2rem;
    font-weight: 200;
  }
}
</style>