import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref, computed } from '@vue/composition-api'
import { queryProcess } from '@/@core/queries/process'
import { queryEvents, queryEvent, queryEventId, addEvent, updateEvent } from '@/@core/queries/calendar'
import { queryConfirmation, confirmProcess, updateConfirmation } from '@/@core/queries/confirmations'
import { updateImprovement } from '@/@core/queries/improvements'
import axios from '@axios'
import i18n from '@/libs/i18n'
import awsConnection from '@/views/habit/aws';
import useCommon from '@/views/organization/useCommon'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import { get, set } from 'idb-keyval'
import realmConnection from '@/views/habit/realm'

export default function useConfirmationForm(isNewConfirmation) {
  const userData = store.state?.userStore?.userData
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError, storeDataToUpload, formatDate, updateConfirmationWithKey, createImprovements, createCase } = useCommon()
  const { router, route } = useRouter()
  const { singleUpload } = awsConnection()
  const { sumTimes } = useCommonDashboards()
  const { sendImprovementEmail } = useCommonTodo()
  const { getItemsWithAggregate, ObjectId } = realmConnection()

  const continueConfirmation = ref(!isNewConfirmation)
  const time = ref("00:00:00")
  const timeBegan = ref(null)
  const interval = ref(null)
  const isOnline = computed(() => store.state.app.isOnline)

  const process = ref({})
  const event = ref({})
  const selectedWorker = ref('')
  const selectedSupervisor = ref('')
  const metadataVisible = ref(route.value.params.event || route.value.params.user ? true : false)
  const { default_view_pc_team, default_view_daily_dialogue, new_confirmation_date_variable } = JSON.parse(localStorage.getItem("clientData") || '{}')

  const isSubmitting = ref(false)
  const improvements = ref([])
  const commitments = ref([])
  const filesToUpload = ref([])

  // if (userData.role === 'supervisor' || userData.role === 'consultant') {
  //   selectedSupervisor.value = userData.worker_id.$oid
  // }

  let selectWorkerDisabled = false
  const selectSupervisorDisabled = ref(!isNewConfirmation)
  const selectDateDisabled = ref(isNewConfirmation ? false : !JSON.parse(localStorage.getItem("clientData") || "{}").new_confirmation_date_variable)

  if (route.value.params.user) {
    continueConfirmation.value = true
    // selectSupervisorDisabled.value = true
    selectDateDisabled.value = true
    if (route.value.params.user !== "worker") {
      selectWorkerDisabled = true
      selectedWorker.value = route.value.params.user
    }
  }
  event.value.date = new Date()

  const enableConfirmation = () => {
    continueConfirmation.value = true
    // selectSupervisorDisabled.value = true
    selectDateDisabled.value = !new_confirmation_date_variable
    !new_confirmation_date_variable ? (event.value.date = new Date()) : null;
    // selectedSupervisor.value = userData.worker_id?.$oid
    metadataVisible.value = !metadataVisible.value
    startTimer()
  }

  const show = async () => {
    if (isOnline.value) {
      isSubmitting.value = true

      if (isNewConfirmation) {
        try {
          const pipeline = getPipeline()
        
          const items = await getItemsWithAggregate({ collection: 'process', pipeline })
    
          process.value = items[0] || {}
          if (process.value.evaluator) selectedSupervisor.value = String(process.value.evaluator)
          if (process.value.evaluated) selectedWorker.value = String(process.value.evaluated)
        } catch (error) {
          console.log(error)
          showErrorMessage(i18n.t('message.process_list_fetch_error'))
        } finally {
          isSubmitting.value = false
        }
      } else {
        axios
          .post('/graphql', {
            query: queryConfirmation,
            variables: { query: { _id: route.value.params.id } },
          })
          .then(({ data }) => {
            if (data.errors) throw new Error(data.errors[0].message)
  
            const oldConfirmation = data.data.confirmation || {}
            process.value = {
              ...oldConfirmation.process,
              metadata: oldConfirmation.metadata,
              activities: oldConfirmation.activities,
              duration: oldConfirmation.duration,
              confirmationId: oldConfirmation._id
            }
            selectedWorker.value = oldConfirmation.worker?._id
            selectedSupervisor.value = oldConfirmation.supervisor?._id
            event.value.date = oldConfirmation.date
          })
          .catch((error) => {
            console.log(error)
            handleError({ error, defaultMessage: i18n.t('message.process_fetch_error') })
          })
          .finally(() => {
            isSubmitting.value = false
          })
      }
    } else {
      get('processesComplete').then(response => {
        if (response) {
          process.value = response.find(e => e._id === route.value.params.id)
          if (!process.value) handleError({ isStorageError: true })
        }
        else if (localStorage.processesComplete) {
          process.value = JSON.parse(localStorage.processesComplete).find(e => e._id === route.value.params.id)
          if (!process.value) handleError({ isStorageError: true })
        }
        else handleError({ isStorageError: true })
      })
    }
  }

  const schedulleEvent = async (calendar, confirmationId, isDraft) => {
    const startDateTime = formatDate(event.value.date)
    const endDateTime = formatDate(event.value.date, 13)
    // If an confirmationId exists, try to looking for an event with extendedProps.calendar != "Realizadas" and update that event instead 
    // of create a new event in the calendar. If all the events for the attendee have a confirmationId and his prop 
    // extendedProps.calendar == "Realizadas" continue with the code and create a event.
    if (confirmationId) { 
      const firstDayOfCurrentMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth(), 1)
      const firstDayOfNextMonth = new Date(startDateTime.getFullYear(), startDateTime.getMonth() + 1, 1)
      const query = {
        extendedProps : { calendar_ne : "Realizadas"}, 
        start_gte : firstDayOfCurrentMonth, 
        end_lt : firstDayOfNextMonth,
        attendee: { _id: selectedWorker.value},
        process: { _id: process.value._id}
      }
      try { 
        const eventsToSearch = await axios.post('/graphql', {
          query: queryEvents,
          variables: {query, sortBy: "START_ASC"}
        })
        if (eventsToSearch.data.errors) throw new Error(eventsToSearch.data.errors[0].message)

        const eventExist = eventsToSearch.data?.data?.events
        if (eventExist?.length) {
          const queryDos = {
            query: updateEvent,
            variables: {
              query: {
                _id: eventExist[0]._id
              },
              data: {
                extendedProps: { calendar: "Realizadas"},
                start: startDateTime,
                end: endDateTime,
                confirmation: { link: confirmationId }
              }
            }
          }

          // If there are new improvements, create improvements and merge them with old improvements from the event if there are any
          // if (improvements.value.length) {
          //   try {
          //     const newImprovementIds = await createImprovements(improvements.value, "event", eventExist[0]._id)
          //     const oldImprovementIds = eventExist[0].improvements?.map(e => e._id) || []
          //     queryDos.variables.data.improvements = {
          //       link: [...oldImprovementIds, ...newImprovementIds]
          //     }
          //   } catch (error) {
          //     console.log(error)
          //   }
          // }

          try { 
            const eventToUpdate = await axios.post('/graphql', queryDos)
            if (eventToUpdate.data.errors) throw new Error(eventToUpdate.data.errors[0].message)
            return router.push({ name: 'habit-confirmation-view', params: { id: confirmationId } })      
          } catch (error) {
            console.log(error)
            showErrorMessage(i18n.t('message.event_update_error'))
          }
        }
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.event_update_error'))
      }
    }

    if (!selectedSupervisor.value) {
      showErrorMessage(i18n.t('message.observerRequired'))
      return
    }

    isSubmitting.value = true

    const payload = {
      client_id: { link: userData.client.$oid },
      // eslint-disable-next-line no-underscore-dangle
      process: { link: process.value._id },
      attendee: selectedWorker.value ? { link: selectedWorker.value } : null,
      organizer: { link: selectedSupervisor.value },
      start: startDateTime,
      end: endDateTime,
      confirmation: confirmationId ? { link: confirmationId } : null,
      extendedProps: {
        calendar: calendar || 'Pendientes',
      },
    }

    // if (improvements.value.length) {
    //   // Upload documents to AWS and then store document keys in an array
    //   const uploadDocumentPromises = improvements.value.map(i => {
    //     if (!i.documentFile?.name) return null
    //     const destinationFolder = `${userData.client.$oid}/documents`
    //     return new Promise((resolve, reject) => {
    //       singleUpload(i.documentFile, destinationFolder)
    //         .then((key) => resolve(key))
    //         .catch((err) => {
    //           console.log(err)
    //           resolve(null)
    //         })
    //     })
    //   })
    //   const documentKeys = await Promise.all(uploadDocumentPromises)

    //   // Set improvements property
    //   payload.improvements = {
    //     create: improvements.value.map((c, index) => {
    //       const newImprovement  = {
    //         client_id: c.client_id,
    //         assignee: c.assignee ? { link: c.assignee._id } : null,
    //         note: c.note,
    //         tags: c.tags,
    //         description: c.description,
    //         dueDate: c.dueDate ? new Date(`${c.dueDate.slice(0, 10)} 12:00:00`) : null,
    //         important: c.important,
    //       }
    //       if (c.documentFile?.name) newImprovement.imageKey = documentKeys[index]
    //       if (c.metadata?.length) {
    //         newImprovement.metadata = c.metadata.map(m => ({
    //           name: m.name,
    //           type: m.type,
    //           answer: m.answer,
    //         }))
    //       }
    //       if (c.associatedCase) {
    //         newImprovement.associatedCase = { link: c.associatedCase }
    //       }
    //       return newImprovement
    //     })
    //   }
    // }

    if (isOnline.value) {
      postEvent(payload, confirmationId, isDraft)
    }
    else {
      storeDataToUpload('eventsToUpload', payload)
      // let eventsStored = localStorage.eventsForSchedule ? JSON.parse(localStorage.eventsForSchedule) : []
      // eventsStored.push({...payload})
      // localStorage.eventsForSchedule = JSON.stringify(eventsStored)
      showSuccessMessage(i18n.t('message.form_stored'))
      if(default_view_pc_team) router.push({ name: 'apps-pc-team' })
      else if(default_view_daily_dialogue) router.push({ name: 'habit-meeting-new' })
      else router.push({ name: 'apps-calendar' })
    }
  }

  const postEvent = (payload, confirmationId, isDraft) => {
    // eslint-disable-next-line import/no-named-as-default-member
    axios
      .post('/graphql', {
        query: addEvent,
        variables: { data: payload },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
        showSuccessMessage(i18n.t('message.confirmation_agenda'))
      
        // Get event to obtain the improvements ids and update each one to add origin and origin_id from event
        // Also send emails to the improvements assignee
        const newEventId = data.data.insertOneEvent._id
        if (newEventId) {
          axios
            .post('/graphql', {
              query: queryEvent,
              variables: { query: { _id: newEventId } }
            })
            .then((response) => {
              if (response.data.errors) throw new Error(response.data.errors[0].message)
              const improvementsToUpdate = response?.data?.data?.event?.improvements || []
              improvementsToUpdate.forEach(element => {
                // Update improvement
                axios
                  .post('/graphql', {
                    query: updateImprovement,
                    variables: { query: { _id : element._id }, data: { origin_id: newEventId, origin: "event" } }
                  })
                  .then((response) => {
                    if (response.data.errors) throw new Error(response.data.errors[0].message)
                  })
                  .catch((err) => {
                    console.log(err)
                    showErrorMessage(i18n.t('message.improvement_update_error'))
                  })

                // Send email to assignee
                if (element.assignee?.email) sendImprovementEmail(element)
              });
            })
            .catch((err) => {
              console.log(err)
              showErrorMessage(i18n.t('message.improvement_update_error'))
            })
        }

        if (isDraft) router.push({ name: 'organization-pending-confirmations' })
        else if (confirmationId) router.push({ name: 'habit-confirmation-view', params: { id: confirmationId } })
        else {
          if (default_view_pc_team) router.push({ name: 'apps-pc-team' })
          else if (default_view_daily_dialogue) router.push({ name: 'habit-meeting-new' })
          else router.push({ name: 'apps-calendar' })
        }
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.no_confirmation_agenda'))
        isSubmitting.value = false
      })
  }

  const updateEventWithConfirmation = async (confirmationId, isDraft) => {
    const eventId = route.value.params.event
      ? route.value.params.event
      : isNewConfirmation
        ? null
        : await getEventId(confirmationId)
    const processId = isNewConfirmation ? route.value.params.id : null
    const statusEvent = isDraft ? "Pendientes" : "Realizadas"
    
    if (!confirmationId || !eventId) {
      schedulleEvent(statusEvent, confirmationId, isDraft)
      return
    }

    const payload = {
      confirmation: { link: confirmationId },
      extendedProps: {
        calendar: statusEvent,
      },
      attendee: { link: selectedWorker.value },
    }

    if (processId) payload.process = { link: processId }

    if (!isDraft) {
      const startDateTime = formatDate(event.value.date)
      const endDateTime = formatDate(event.value.date, 13)

      payload.start = startDateTime
      payload.end = endDateTime
    }

    // If there are new improvements, create improvements and get event to see if it already has improvements to merge them
    // if (improvements.value.length) {
    //   try {
    //     const newImprovementIds = await createImprovements(improvements.value, "event", eventId)
  
    //     const eventResponse = await axios.post('/graphql', { query: queryEvent, variables: { query: { _id: eventId } } })
    //     if (eventResponse.data?.errors) throw new Error(eventResponse.data.errors[0].message)
    //     const oldImprovementIds = eventResponse.data?.data?.event?.improvements?.map(e => e._id) || []
    //     payload.improvements = {
    //       link: [...oldImprovementIds, ...newImprovementIds]
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    axios
      .post('/graphql', {
        query: updateEvent,
        variables: {
          query: { _id: eventId },
          data: payload,
        },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
        showSuccessMessage(i18n.t('message.Event_updated'))
        if (isDraft) router.push({ name: 'organization-pending-confirmations' })
        else router.push({ name: 'habit-confirmation-view', params: { id: confirmationId } })
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.event_update_error'))
        isSubmitting.value = false
      })
  }

  // eslint-disable-next-line no-shadow
  const submit = (process, imgData, isDraft) => {
    isSubmitting.value = true
    stopTimer()

    let payload = {
      client_id: { link: userData.client.$oid },
      process: { link: String(process._id) },
      worker: selectedWorker.value ? { link: selectedWorker.value } : null,
      supervisor: { link: selectedSupervisor.value },
      activities: process.controlsData?.map((control, controlIndex) => ({
        name: control.name,
        behaviours: control.behavioursData?.map((behaviour, behaviourIndex) => {
          if (behaviour.improvement) improvements.value.push(behaviour.improvement)

          if (behaviour.file) {
            filesToUpload.value.push({
              file: behaviour.file,
              clientId: userData.client.$oid,
              riskId: String(process._id),
              controlIndex,
              behaviourIndex,
            })
          }

          return {
            name: behaviour.name,
            type: behaviour.type,
            answer: behaviour.answer,
            expectedAnswer: behaviour.expectedAnswer,
          }
        }),
      })),
      duration: isNewConfirmation ? time.value : sumTimes(process.duration, time.value),
      metadata: process.metadata?.map(metadata => ({
        name: metadata.name,
        type: metadata.type,
        answer: metadata.answer,
      })),
      date: formatDate(event.value.date),
      pending: isDraft
    }

    if (isOnline.value) {
      postConfirmation(payload, imgData, isDraft)
    }
    else if (!isDraft) {
      if (route.value.params.event) {
        payload.eventId = route.value.params.event
        // TODO: use update method instead of get + set when localStorage is not used anymore
        get('events').then(response => {
          if (response) {
            for (let e of response) {
              if (e._id === payload.eventId) {
                e.extendedProps.calendar = "Realizadas"
                break
              }
            }
            set('events', response).then(() => {}).catch(error => console.log(error))
          } else {
            let eventsStored = localStorage.events ? JSON.parse(localStorage.events) : []
            for (let e of eventsStored) {
              if (e._id === payload.eventId) {
                e.extendedProps.calendar = "Realizadas"
                break
              }
            }
            localStorage.events = JSON.stringify(eventsStored)
          }
        })
      }
      payload.imgData = imgData
      payload.improvements = improvements.value
      storeDataToUpload('confirmationsToUpload', payload)
      // let confirmationsStored = localStorage.confirmations ? JSON.parse(localStorage.confirmations) : []
      // confirmationsStored.push({...payload})
      // localStorage.confirmations = JSON.stringify(confirmationsStored)
      showSuccessMessage(i18n.t('message.form_stored'))
      if (default_view_pc_team) router.push({ name: 'apps-pc-team' })
      else if (default_view_daily_dialogue) router.push({ name: 'habit-meeting-new' })
      else router.push({ name: 'apps-calendar' })
    }
  }

  const postConfirmation = (payload, imgData, isDraft) => {
    const graphqlVariables = { data: payload }
    if (!isNewConfirmation) graphqlVariables.query = { _id: process.value.confirmationId }

    axios
      .post('/graphql', {
        query: isNewConfirmation ? confirmProcess : updateConfirmation,
        variables: graphqlVariables,
      })
      .then(async response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const confirmationId = response.data.data[`${isNewConfirmation ? 'insert' : 'update'}OneConfirmation`]._id

        if (isDraft) {
          showSuccessMessage(i18n.t('message.confirmation_draft_saved'))
        } else {
          showSuccessMessage(i18n.t('message.confirmation_created'))
          
          // Upload image to AWS and then update the confirmation in MongoDB with the AWS image key
          const {fileInfo, destinationFolder} = imgData
          if (fileInfo) {
            singleUpload(fileInfo, destinationFolder)
              .then((key) => updateConfirmationWithKey(confirmationId, key))
              .catch((err) => console.log(err))
          }
        }
        
        updateEventWithConfirmation(confirmationId, isDraft)

        // Create cases from behaviours
        if (improvements.value.length) {
          improvements.value.forEach(e => {
            e.confirmation = { link: confirmationId }
            e.assignee = { link: e.assignee }
            e.proposalResponsible = { link: e.assignee.link }
            e.approvalResponsible = e.approvalResponsible ? { link: e.approvalResponsible } : null
            e.verificationResponsible = e.verificationResponsible ? { link: e.verificationResponsible } : null
            e.dueDate = e.dueDate ? formatDate(e.dueDate.slice(0, 10)) : null
            e.proposalDueDate = e.proposalDueDate ? formatDate(e.proposalDueDate.slice(0, 10)) : null
            e.approvalDueDate = e.approvalDueDate ? formatDate(e.approvalDueDate.slice(0, 10)) : null
            e.verificationDueDate = e.verificationDueDate ? formatDate(e.verificationDueDate.slice(0, 10)) : null
            e.associatedCases = e.associatedCases ? { link: e.associatedCases } : null
            e.areas = e.areas ? { link: e.areas } : null
            e.stage = 'analysis'
            e.lastModifiedBy = userData?.worker_id?.$oid ? { link: userData.worker_id.$oid } : null,

            createCase(e)
          })
        }

        // Upload files to AWS and then update the confirmation in MongoDB with the AWS file keys
        if (filesToUpload.value.length) {
          const uploadFilesPromises = filesToUpload.value.map(e => {
            const destinationFolder = `${e.clientId}/${e.riskId}/${confirmationId}/${e.controlIndex}/${e.behaviourIndex}`
            return new Promise((resolve, reject) => {
              singleUpload(e.file, destinationFolder)
                .then((key) => resolve({
                  key,
                  controlIndex: e.controlIndex,
                  behaviourIndex: e.behaviourIndex
                }))
                .catch((err) => {
                  console.log(err)
                  showErrorMessage(i18n.t('message.evidence_upload_error'))
                  resolve(null)
                })
            })
          })

          const fileKeys = await Promise.all(uploadFilesPromises)

          fileKeys.forEach(e => {
            if (!e) return
            payload.activities[e.controlIndex].behaviours[e.behaviourIndex].evidenceKey = e.key
          })

          updateConfirmationWithKeysInBehaviours(confirmationId, payload.activities)
        }
      })
      .catch((err) => {
        console.log(err)
        showErrorMessage(i18n.t('message.confirmation_error'))
        isSubmitting.value = false
      })
  }

  const getEventId = (confirmationId) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/graphql", {
          query: queryEventId,
          variables: { query: { confirmation: { _id: confirmationId } } },
        })
        .then(({data}) => {
          if (data.errors) throw new Error(data.errors[0].message)

          resolve(data.data.event._id)
        })
        .catch((error) => {
          console.log(error)
          showErrorMessage(i18n.t('message.event_update_error'));
          reject()
        })
    })
  }

  const updateConfirmationWithKeysInBehaviours = (confirmationId, activities) => {
    axios
      .post("/graphql", {
        query: updateConfirmation,
        variables: { query: { _id: confirmationId }, data: { activities } },
      })
      .then(({data}) => {
        if (data.errors) throw new Error(data.errors[0].message)
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.confirmation_update_error'));
        reject()
      })
  }

  // Timer
  const startTimer = () => {      
    timeBegan.value = new Date()
    interval.value = setInterval(clockRunning, 1000)
  }
  
  const stopTimer = () => {
    clearInterval(interval.value)
  }
    
  const clockRunning = () => {
    const currentTime = new Date()
    , timeElapsed = new Date(currentTime - timeBegan.value)
    , hour = timeElapsed.getUTCHours()
    , min = timeElapsed.getUTCMinutes()
    , sec = timeElapsed.getUTCSeconds()
    // , ms = timeElapsed.getUTCMilliseconds()
    
    time.value = zeroPrefix(hour, 2) + ":" + zeroPrefix(min, 2) + ":" + zeroPrefix(sec, 2)
    // zeroPrefix(ms, 3);
  }
  
  const zeroPrefix = (num, digit) => {
    let zero = '';
    for(let i = 0; i < digit; i++) {
      zero += '0';
    }
    return (zero + num).slice(-digit);
  }

  const getPipeline = () => {
    const filter = { _id: ObjectId(route.value.params.id) }

    return [
      { $match: filter },
      {
        $lookup: {
          from: "control",
          localField: "controls",
          foreignField: "_id",
          as: "controlsData",
          pipeline: [
            { $project: { name: 1, behaviours: 1 } },
            {
              $lookup: {
                from: 'behaviour',
                localField: 'behaviours',
                foreignField: '_id',
                pipeline: [ { $project: { name: 1, type: 1, expectedAnswer: 1 } } ],
                as: 'behavioursData',
              },
            },
            {
              $addFields: {
                behavioursData: {
                  $map: {
                    input: "$behaviours",
                    as: "behaviourId",
                    in: { $arrayElemAt: [ "$behavioursData", { $indexOfArray: ["$behavioursData._id", "$$behaviourId"] } ] }
                  }
                }
              }
            }
          ]
        }
      },
      {
        $addFields: {
          controlsData: {
            $map: {
              input: "$controls",
              as: "controlId",
              in: { $arrayElemAt: [ "$controlsData", { $indexOfArray: ["$controlsData._id", "$$controlId"] } ] }
            }
          }
        }
      },
    ]
  }

  return {
    userData,
    show,
    submit,
    event,
    process,
    selectedWorker,
    selectWorkerDisabled,
    selectedSupervisor,
    selectSupervisorDisabled,
    selectDateDisabled,
    schedulleEvent,
    enableConfirmation,
    continueConfirmation,
    isSubmitting,
    postConfirmation,
    postEvent,
    interval,
    startTimer,
    stopTimer,
    metadataVisible,
    improvements,
  }
}
